import {
  useFetchUserInvitation,
  useFetchUserInvites,
} from "src/data/organizations/queries/inviteQueries"
import {
  InvitationState,
  IOrganizationInvitationUserFilter,
} from "src/data/organizations/types/organizationTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { getInviteCodeFromUrl } from "src/router/organizationRoutes"

export function useGetInvitations(props?: {
  filter: IOrganizationInvitationUserFilter
}) {
  const { t, langKeys } = useTranslate()
  const inviteCode = getInviteCodeFromUrl()
  const user = useGetUser()

  const checkSpecificInvitation = !!inviteCode

  const fetchUserInvitations = useFetchUserInvites({
    userId: user.user_id,
    filter: {
      invitation_state: InvitationState.PENDING,
      limit: 300,
      ...props?.filter,
    },
    options: {
      enabled: checkSpecificInvitation === false,
    },
  })

  const fetchUserInvitation = useFetchUserInvitation({
    userId: user.user_id,
    invitationCode: inviteCode || "",
    options: {
      enabled: checkSpecificInvitation === true,
    },
  })

  const loading =
    checkSpecificInvitation === true
      ? fetchUserInvitation.isInitialLoading
      : fetchUserInvitations.isInitialLoading

  function getErrorMsg() {
    if (fetchUserInvitations.isError) {
      return t(langKeys.failed_something_went_wrong)
    }

    if (
      inviteCode &&
      (fetchUserInvitation.isError || !fetchUserInvitation.data)
    ) {
      return t(langKeys.organizations_invitation_not_found_error)
    }

    if (fetchUserInvitation.data?.state === InvitationState.ACCEPTED) {
      return t(langKeys.organizations_invitation_already_accepted_error)
    }
  }

  function getInvitations() {
    if (inviteCode) {
      return fetchUserInvitation.data ? [fetchUserInvitation.data] : []
    }
    return fetchUserInvitations.data ?? []
  }

  return {
    invitationsLoading: loading,
    errorMsg: getErrorMsg(),
    invitations: getInvitations(),
  }
}
