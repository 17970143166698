import styled from "styled-components"

import { OrganizationAvatar } from "src/components/Account/Avatars"
import { TOrganizationRole } from "src/data/organizations/types/organizationMemberTypes"
import {
  InvitationType,
  TOrganizationInvitation,
} from "src/data/organizations/types/organizationTypes"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { RadioGroup } from "src/ui/RadioGroup/RadioGroup"
import { spacing } from "src/ui/spacing"

export type TSelectedInvitation = {
  code: string | undefined
  role: TOrganizationRole
}

export function StepInvitations({
  invitations,
  selectedInvitation,
  onSelectInvitation,
  errorMsg,
}: {
  invitations: TOrganizationInvitation[]
  selectedInvitation: TSelectedInvitation | undefined
  onSelectInvitation: (invite: TSelectedInvitation) => void
  errorMsg: string | undefined
}) {
  const { t, langKeys } = useTranslate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const options: { value: TSelectedInvitation; render: any }[] =
    invitations.map((invite) => ({
      value: {
        code: invite.code,
        role: invite.role,
      },
      render: () => {
        const roleLangKey = getLangKeyRole(invite)
        return (
          <OptionBox>
            <OrganizationAvatar orgName={invite.invitation_target_name} />
            <div>
              <MText variant="subtitle" color="secondary">
                {invite.invitation_target_name}
              </MText>
              <MText variant="bodyS">{t(roleLangKey)}</MText>
            </div>
          </OptionBox>
        )
      },
    }))

  useEffectOnce(() => options[0] && onSelectInvitation(options[0]?.value))

  if (!!errorMsg) {
    return (
      <InvitationsCardHeader>
        <MBanner type="error" fullWidth>
          {errorMsg}
        </MBanner>
      </InvitationsCardHeader>
    )
  }

  if (invitations.length <= 0) {
    return (
      <InvitationsCardHeader>
        <div>{t(langKeys.organizations_no_current_invitations)}</div>

        <div>
          <MText variant="subtitle" color="secondary">
            {t(langKeys.organizations_get_invitations_question)}
          </MText>
          <MText variant="body" color="secondary">
            {t(langKeys.organizations_get_invitations_response)}
          </MText>
        </div>
      </InvitationsCardHeader>
    )
  }

  return (
    <InvitationsCardHeader>
      <RadioGroup<TSelectedInvitation>
        options={options}
        value={selectedInvitation}
        onChange={(invite) => onSelectInvitation(invite)}
        compare={(a, b) => a?.code === b?.code}
        hideRadio
      />
    </InvitationsCardHeader>
  )
}

function InvitationsCardHeader({ children }: { children: React.ReactNode }) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <MText variant="heading2">{t(langKeys.invitation_plural)}</MText>

      {children}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
  margin-top: ${spacing.L};
`

const OptionBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

function getLangKeyRole(invite: TOrganizationInvitation) {
  switch (invite.type) {
    case InvitationType.HOME:
      return getHomeRoleKey(invite)
    case InvitationType.HOMEGROUP:
      return getHomeGroupRoleKey(invite)
    case InvitationType.ORGANIZATION:
    default:
      return getOrganizationRoleKey(invite)
  }
}

function getHomeRoleKey(invite: TOrganizationInvitation) {
  switch (invite.role) {
    case "admin":
      return langKeys.organizations_role_home_admin
    case "member":
      return langKeys.organizations_role_home_member
    case "responder":
      return langKeys.organizations_role_home_responder

    default:
      throw Error(`Invalid invite role ${invite.role}`)
  }
}

function getHomeGroupRoleKey(invite: TOrganizationInvitation) {
  switch (invite.role) {
    case "admin":
      return langKeys.organizations_homegroup_admin_role_title
    case "member":
      return langKeys.organizations_homegroup_member_role_title
    case "responder":
      return langKeys.organizations_homegroup_responder_role_title

    default:
      throw Error(`Invalid invite role '${invite.role}'`)
  }
}

function getOrganizationRoleKey(invite: TOrganizationInvitation) {
  switch (invite.role) {
    case "admin":
      return langKeys.organizations_admin_role_title
    case "member":
      return langKeys.organizations_member_role_title
    case "responder":
      return langKeys.organizations_responder_role_title

    default:
      throw Error(`Invalid invite role '${invite.role}'`)
  }
}
