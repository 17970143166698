import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { systemOkForeground } from "src/ui/colors"
import CheckMark from "src/ui/icons/check-circle.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { Optional } from "src/utils/tsUtil"

interface IJoinedOrg {
  id: string
  name: string
}

export function StepJoinOrgSuccess({
  joinedOrg,
}: {
  joinedOrg: Optional<IJoinedOrg>
}) {
  const { t, langKeys } = useTranslate()

  return (
    <SuccessBox>
      <StyledCheckMark fill={systemOkForeground} width={48} />
      <MText variant="heading2">
        {t(langKeys.organization_join_success_title, [joinedOrg?.name])}
      </MText>
    </SuccessBox>
  )
}

const SuccessBox = styled.div`
  display: grid;
  justify-content: center;
  gap: ${spacing.L};
`

const StyledCheckMark = styled(CheckMark)`
  justify-self: center;
`
